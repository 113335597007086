import { Box, Link } from "@chakra-ui/react";
import * as React from "react"
import { Trans, useTranslation } from "react-i18next";
import SEO from "../modules/seo";
import { PageContext } from "../types/pagecontext";

interface Props {
  pageContext: PageContext
}
// markup
const NotFoundPage = ({pageContext}: Props) => {
  const { t } = useTranslation();
  return (
    <>
    <SEO title={t("siteMetadata.title")+" | 404"} description={t("pages.404.description")}></SEO>
      <Box>
        <Trans components={[<Link color="#00bfb1" href={"/"+pageContext.lang} />]} t={t} i18nKey="pages.404.paragraphs" />
      </Box>
    </>
  )
}

export default NotFoundPage